import { Injectable } from '@angular/core';
import { RestApiService } from '../api.service';
import { environment } from '../../environments/environment';
import { HttpClientService } from '../http-client.service';
import { ILocation } from './subscription.service';
import { ICreatedOrUpdatedBy } from './pre-booking-api.service';

export enum PartyOrderStatus {
  ORDER_DELIVERED = 'orderDelivered',
  ORDER_CANCELLED = 'orderCancelled',
  NO_RESPONSE = 'noResponse',
  NOT_INTERESTED = 'notInterested',
  OUT_OF_ZONE = 'outOfZone',
  CONTACTED = "contacted",
}
export interface IBillDetailsPayload {
  billDetails?: {
    amount: number;
    deliveryCharge: number;
  },
  note?: string;
  status?: string;
  remarks?: string;
  partyOrderId?: string;
}

export interface IPartyOrderFilter {
  city: string;
  status: string;
  search: string;
  from: string;
  to: string;
}

export interface IBillDetails {
  netAmount: number;
  billedAmount: number;
  sgst: number;
  cgst: number;
  igst: number;
  roundOff: number;
  deliveryCharge: number;
  amount: number;
}

export interface INotesLog {
  partyOrderId: string;
  note: string;
  createdBy: ICreatedOrUpdatedBy;
  partyOrderNoteId: string;
  createdAt: string;
}

export interface IPartyItems {
  _id: string;
  partyOrderId: string;
  name: string;
  email: string;
  address: string;
  pinCode: string;
  landmark: string;
  city: string;
  deliveryDateTime: string;
  menu: string;
  foodType: string;
  events: Array<string>;
  createdAt: string;
  updatedAt: string;
  __v: number;
  location: ILocation;
  phoneNumber: string;
  paymentInitiatedBy: ICreatedOrUpdatedBy;
  paymentId: string;
  status: string;
  paymentDetails: {
    _id: string;
    mode: string;
    billDetails: IBillDetails;
    amount: number;
    paymentId: string;
  };
  notes: string;
  billDetails: IBillDetails;
  quantity: number;
  remarks: string;
  vegQuantity: number;
  nonVegQuantity: number;
  isEligibleToGeneratePaymentLink: boolean;
  altPhoneNumber: string;
  isFirstTimeCustomer: boolean;
  customerId: string;
  endDate: string;
  userDefinedEventType: string;
  budgetAmount: number;
  partyOrdersNotes: Array<INotesLog>;
}

export interface IPartyOrderMetrics {
  orderConfirmed: number;
  totalAmountReceived: string;
}

export interface IPaginatedResults {
  totalItems: number;
  totalPages: number;
  skip: number;
  limit: number;
  items: Array<IPartyItems>;
}
export interface IAllPartyOrderResponse {
  paginatedResults: IPaginatedResults;
  metrics: IPartyOrderMetrics;
}

@Injectable()
export class PartyOrderService extends RestApiService {
  protected subscriptionApiUrl = environment.config['SUBSCRIPTION_SERVICE_URL'];

  private servicePath = `${this.subscriptionApiUrl}/party-orders`;

  constructor(http: HttpClientService) {
    super(http);
    this.authenticatedRoute = true;
  }

  async getAllPartyOrders(queryParams: Partial<any>)
    : Promise<{ data: IAllPartyOrderResponse; }> {
    const searchParams = new URLSearchParams();
    const filter: IPartyOrderFilter = {} as IPartyOrderFilter;
    if (queryParams) {
      if (queryParams['city']) {
        filter.city = queryParams['city'];
      }
      if (queryParams['status']) {
        filter.status = queryParams['status'];
      }
      if (queryParams['limit']) {
        searchParams.set('limit', String(queryParams['limit']));
      }
      if ('skip' in queryParams) {
        searchParams.set('skip', String(queryParams['skip'] ?? 0));
      }
      if (queryParams['startDate'] !== undefined) {
        filter.from = queryParams['startDate'];
      }
      if (queryParams['endDate'] !== undefined) {
        filter.to = queryParams['endDate'];
      }
      if ('search' in queryParams) {
        filter.search = queryParams['search'];
      } else {
        searchParams.delete('search');
      }
    }
    if (Object.keys(filter).length) {
      searchParams.set('filter', JSON.stringify(filter));
    }
    const apiPath = `${this.servicePath}?${searchParams.toString()}`;
    return this.httpGet(apiPath);
  }

  async generatePaymentLink(partyOrderId: string) {
    const apiPath = `${this.servicePath}/${partyOrderId}/initiate-payment`;
    return this.httpPatch(apiPath);
  }

  async updatePartyOrderDetail(partyOrderId: string, payload: IBillDetailsPayload) {
    const apiPath = `${this.servicePath}/${partyOrderId}`;
    return this.httpPatch(apiPath, payload);
  }

  async updatePartyOrderNotes(payload: IBillDetailsPayload) {
    const apiPath = `${this.subscriptionApiUrl}/party-orders-notes`;
    return this.httpPost(apiPath, payload);
  }

  async updateOrderStatus(partyOrderId: string, paymentId: string) {
    const apiPath = `${this.servicePath}/${partyOrderId}/confirm/payments/${paymentId}`;
    return this.httpPost(apiPath);
  }
}
